import React, { Component, Fragment } from 'react'
import '../termsCondition/terms.scss'
export default class ColoCofTermsConditionsNew extends Component {
	encode = data => {
		let str = data.reduce(function (a, b) {
			return a + String.fromCharCode(b)
		}, '')
		return btoa(str).replace(/.{76}(?=.)/g, '$&\n')
	}
	render() {
		const {
			firstCaps,
			Signcss,
			secondBold,
			imageData,
			imageType,
			isCrf,
			isFlv,
			CustomComponent,
		} = this.props
		const containerClass = isCrf ? 'new-crf-terms' : 'new-caf-terms'
		return (
			<Fragment>
				<page size='A4' className='new-page'>
					{!!CustomComponent && <CustomComponent />}
					<div className='section2 font8'>
						<div className=''>
							<p className='ligthbg font8'>
								Commercial - Terms and Conditions{' '}
							</p>
							<p className='font10'>
								A) Customer is responsible for conducting the User Acceptance
								Testing (‘UAT’) for the Data Center Colocation services / Cloud
								services (“Service(s)”) within the time period mentioned in the
								T&C hereinafter post receipt of delivery confirmation from
								VITSL, otherwise the Services would be deemed as accepted by
								Customer. <br /> <br />
								B) VITSL shall start billing for the Service within 2 days of
								delivery as per point (A) above.
								<br /> <br />
								C) Standard Payment Terms for Service(s): (i) One Time Charges
								shall be payable within fifteen (15) days from the date of first
								bill to Customer; and (ii) Annual Recurring Charges and any
								other Charges agreed between Parties shall be payable on
								Quarterly in advance.
								<br />
								<br />
								D) Early Exit Charges / Termination Charges: If Customer
								terminates the Service prior to the end of the committed
								Contract (Lock in) Period (as mentioned in the Customer Order
								Form [COF]), then Customer shall be liable to pay 100% early
								termination charges for the remaining period of the Contract.{' '}
								<br /> <br />
								E) For any reason if Services are suspended, VITSL will continue
								billing for Services provisioned during the suspended period and
								Customer is liable to pay for the suspension period.
								<br /> <br />
								F) Subject to aforesaid point (C) and (D), Customer should
								provide for any form of termination, a minimum of prior ninety
								(90) day’s notice to VITSL before terminating entire/any part of
								the Service.
								<br /> <br />
								G) In the event the term or usage of a Service extends beyond
								the agreed expiry date or termination date of this COF, then the
								term and conditions of this COF shall be extended to the
								expiration / termination of the applicable colocation Service.
								Customer shall be bound to make payment of the Service during
								the extended period as per last agreed pricing terms.
								<br /> <br />
								H) Parties understand that Services ordered under the COF
								including the Colocation Services will have a 10% YoY price
								increase applicable for Annual Recurring Charges by default.
								<br /> <br />
								<strong> Note- </strong> Customer to provide the GST number of
								the Place of Supply (State where the data center is located) in
								order to avail GST credit
							</p>
						</div>
					</div>
				</page>
				<page size='A4'>
					<div className='section2 font8 '>
						<div className=''>
							<p className='ligthbg font8'>General Terms and Conditions </p>
							<div className='over_hid'>
								<div className='fleft width49'>
									<ul>
										<li>
											1. I/We/Customer agree to avail Services in accordance
											with this General Terms &amp; Conditions (“T&amp;C”)
											forming part of PO &amp; Schedules/Master Service
											Agreement (“MSA”) agreed by Parties as mutually agreed
											terms between Parties on date of ………and valid for
											………contract period. In the event of any conflict the order
											of precedence is (i) T&amp;C with attached BOQ as
											annexure; (ii) Schedules/MSA; (iii) PO/ Orders.
										</li>
										<li>
											2. I/We undertake to comply with all Applicable Laws and
											regulations, including without limitation, the provisions
											of the IT Act 2000 and any amendments or replacements made
											thereto from time to time. Any change in Applicable
											Laws/regulations would be applicable with immediate effect
											unless otherwise specified. I/We are responsible for
											ensuring that the Services are used legally &amp; in
											compliance with the applicable laws of India.
										</li>
										<li>
											3. For provisioning of Service, Customer agrees to raise
											PO on VITSL as per the commercial terms stated herein
											which includes the Lock in Period Charges, Exit Period
											etc. VITSL agrees to provide the Services from the Service
											Commencement Date as stated herein for the Contract Period
											as may be agreed between Parties, unless due to early
											termination as per clauses stated. Also Customer
											undertakes to pay for the Services from Service
											Commencement Date.
										</li>
										<li>
											4. I/We have read, understood and agree to VITSL’s Privacy
											Policy (as updated from time to time) as available at
											www.myvi.com.
										</li>
										<li>
											5. I/We shall, when required, assist VITSL/any Government
											agency to counteract any unlawful activity on the
											Services.
										</li>
										<li>
											6. I/We agree &amp; undertake not use the Services for any
											of the following activities: (a) Shall not use nor allow
											any other party to use Services for any purposes other
											than the purposes permissible and agreed by Parties herein
											including but not limited as stipulated under the
											applicable statutory or regulatory provisions as may be
											amended from time to time. (b) Carry out any changes or
											alteration in any configuration of the equipment used for
											the channel, speed of operation or location of equipment’s
											towards availing of Service. (c) Shall not lease/resell or
											create any third party right over the Services
											provisioned. (d) Use of Services to store any infringed
											property, alarm or personally harass third parties, or to
											prevent proper usage of other access, or to misuse such
											services for another illegal purpose. (e) supporting in :
											(i) sexually explicit, obscene or pornographic content
											(whether in text or graphics); (ii) speech or images that
											are offensive, profane, hateful, threatening, harmful,
											defamatory, libellous, harassing, discriminatory (whether
											based on race, ethnicity, creed, religion, gender, sexual
											orientation, physical disability or otherwise) or that
											promote any illegal activity; and/or (iii) graphic
											violence or (iv) objectionable, obscene, unauthorized or
											any other content, messages or communications infringing
											copyright, Intellectual property right and international
											&amp; domestic cyber laws.
										</li>
										<li>
											7. VITSL shall not be liable for any cost to the Customer
											in any manner whatsoever and/or for any loss of profits,
											goodwill, consequential losses (including any actions
											brought by a third party), whether direct or indirect.
										</li>
										<li>
											8. VITSL makes no warranties or representations, express
											or implied, either in fact or by operation of law,
											statutory or otherwise, including warranties or terms of
											merchantability, satisfactory quality, fitness for
											purpose, title or non- infringement; unless otherwise
											stated in the respective Service Schedules. Customer
											understand the aforesaid warranties and also the fact that
											the Services are provided on ‘as is available basis’ and
											to fullest extent permitted under law. I/We use of Service
											is at their sole risk &amp; VITSL is not responsible for
											the data stored or residing in their account. Customer
											agrees complete responsibility for accessing, downloading
											files and data transferred vide the Services. VITSL is
											only responsible to extend of Scope or terms expressed
											agreed herein.
										</li>
										<li>
											9. I/We shall take precautions in accordance with industry
											best practice to ensure that no Virus is introduced while
											availing of the Service herein.
										</li>
										<li>
											10. I/We shall co-operate or provide all assistance,
											access and share correct, accurate and complete details to
											enable VITSL to resolve any technical or system related
											issues pertaining to the Services. I/We also acknowledge
											that VITSL shall not be responsible for any breach arising
										</li>
										<li>
											11. I/We shall indemnify and keep indemnified VITSL (or
											its officers, employees, affiliates and their agents)
											against all Claims arising under or in connection with:
											(i) Customer’s or End User’s (a) use of the Services for
											any purpose other than as specified in T&amp;C; (b) acts
											or omissions resulting into loss or damage to real and
											tangible property, caused by (or relating to the strict
											liability); (c) storage or transmission of illegal or
											unlawful content; and (d) infringement of privacy and data
											protection laws; and (ii) fines / penalties imposed on
											VITSL by any governmental authorities due to Customer’s or
											End User’s acts or omissions; or (iii) Customer’s gross
											negligence, breach of representations and warranties.
										</li>
										<li>
											12. A Party’s aggregate liability under or in connection
											with the Services (whether is contract, tort (including
											negligence), breach of statutory duty, indemnity or
											otherwise) during consecutive 12 month period starting on
											the date of last signature of the Service Schedule
											(“Liability Period”) shall not exceed to a sum equal to
											hundred per cent (100%) of the aggregate value of all
											Charges paid or payable in respect of the Liability
											Period. Where Charges have been paid or payable for less
											than 12 months in a Liability Period, a Party’s liability
											shall not exceed the average monthly charge paid or
											payable for that Liability Period multiplied by 12. This
											liability cap shall not apply to non-payment of Charges or
											Exit Charges or Indemnification Claims or for wilful
											default or wilful abandonment; or (iii) fraud or
											fraudulent misrepresentation; or (iv) any liability which
											cannot be excluded by law.
										</li>
									</ul>
								</div>
								<div className='fright width49'>
									<ul className='list'>
										<li>
											13. Lock in Period &amp; T&amp;C for all Services
											commences upon Service Commencement Date as agreed in
											writing/ email exchanges.
										</li>
										<li>
											14. We understand and agree that during implementation of
											Services, if there is requirement of additional capex
											required for enabling VITSL to establish Service, then we
											shall be agreeable to pay accordingly for such additional
											capex or expenses, as discussed and agreed between both
											Parties.
										</li>
										<li>
											15. Any travel &amp; man hours requiring VITSL’s support
											shall be charged to the Customer as per actual, unless
											otherwise agreed.
										</li>
										<li>
											16. Shifting of Services from location would be subject to
											VITSL’s feasibility and thereafter will be charged
											additionally and as per actual.
										</li>
										<li>
											17. Either Party may terminate the Services after the
											Initial Term for convenience whereby VITSL can terminate
											after giving thirty (30) calendar day’s written notice and
											Customer to terminate may give ninety (90) calendar day’s
											written notice except that if the termination of the
											Service by the Customer is effective prior to the expiry
											of any relevant Lock in Period, then the Customer shall
											pay the applicable Exit Charge and any other sums payable
											in accordance with the T&amp;C or agreed between Parties.
											Formal written notices to be given only to the registered
											address of the Parties or to such other address as
											communicated by the respective Party from time to time.
											Customer shall also be liable to send a copy of the notice
											on the VITSL email address provided viz.
											__________________ @vodafoneidea.com and keep a copy of
											the acknowledgement for their record and reference.
										</li>
										<li>
											18. In the event, if: (i) VITSL chooses to suspend the
											Services instead of termination due to non-payment of
											Charges due for a period of thirty (30) days, the Customer
											shall be liable to pay the charges during the suspension
											period; or (ii) VITSL may suspend the Services and
											thereafter terminate this Agreement, if: (a) Customer
											doesn’t confirm to requirement mentioned in Clause 14
											above; or (b) Customer fails to make payment of monies due
											with regards to Services; or (c) Customer is declared
											bankrupt or a receiver or trustee is appointed; or (d)
											Customer’s use or attempted use of Service compromises the
											network or systems or place at risk the delivery of
											Service to other users.
										</li>
										<li>
											19. Consequences of Termination: (a) Termination of this
											Service for any reason does not limit any rights or
											remedies to which a Party may be entitled under this
											T&amp;C or by law or at equity (including VITSL’s right to
											Claim (i) the Exit Charges in the event of termination of
											a Service prior to the expiry of the Lock-in Charges, (ii)
											all sums due prior to termination and for Services
											rendered between the issuance of a termination notice
											until the cessation of the applicable Services). (b)
											Termination shall not affect any accrued rights or
											liabilities of either Party, nor the coming into force or
											the continuance in force of any provision of this T&amp;C
											which is expressly or by implication intended to come into
											or continue in force on or after such termination. (c)
											Upon termination of this Agreement VITSL shall retain
											title, and ownership to the Services, and the associated
											Materials used towards provisioning of Services. (d) All
											materials of either Party in the control or possession of
											the other Party that contain or bear the other Party’s
											Intellectual Property Rights or Confidential Information
											shall either at the request of that Party be destroyed or
											returned to that Party including purging of Data within
											fifteen (15) days and without any further notice. (e)
											Termination of this Agreement or any Service hereunder the
											Customer shall promptly return any VITSL property
											including materials, equipment to VITSL; in case of
											failure by Customer to return then VITSL shall have right
											to charge back the Customer in their final invoice. (f)
											Termination of any Service will not affect any other
											Services.
										</li>
										<li>
											20. I/We acknowledges that VITSL has no editorial right
											over any information stored or transmitted on use of the
											Services by the Customer.
										</li>
										<li>
											21. Parties shall not use other Party’s marks or register
											any confusingly similar devices, logos, trade names,
											trademark or domain names and shall not do any act
											detrimental to the other Party’s marks or goodwill or
											reputation.
										</li>
										<li>
											22. Each Party shall comply with all Applicable Law (as
											applicable to it) and the Customer shall not do or omit to
											do any act or thing which may prejudice the interests of
											VITSL. Notwithstanding the aforesaid or Clause 21 herein,
											a non-breaching Party may terminate this T&amp;C
											unilaterally if there is breach of material terms by a
											breaching Party of this T&amp;C and which is not resolved
											within thirty (30) days of written notice. Each Party
											shall also comply with all Applicable Laws relating to
											bribery and corruption and (i) shall not do, or omit to
											do, any act that will cause the other Party to be in
											breach of any such Applicable Law, (ii) shall not give or
											receive any bribes, including in relation to any public
											official; and (iii) shall maintain an effective
											anti-bribery compliance regime that monitors compliance
											and detects violations.
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</page>
				<page size='A4'>
					<div className='section2 font8'>
						<div className='over_hid'>
							<div className='fleft width49'>
								<ul className='list'>
									<li>
										23. Parties do hereby consent that all Confidential
										Information or documentation disclosed between them relating
										to business including but not limited of technical nature or
										involves research and development, that is rightfully
										obtained, will be kept under strict confidentiality and the
										recipient Party shall not be permitted to reproduce any
										copies or ownership of the same. The obligation of
										confidentiality shall be valid till the tenure of T&amp;C.
									</li>
									<li>
										24. A Party in breach of this T&amp;C shall indemnify &amp;
										keep indemnified the other Party against all or any losses,
										damages and claims arising under or in connection with (i)
										any breach of the provisions on compliance with Applicable
										Law; or (ii) any breach of the provisions on compliance with
										Anti-bribery &amp; Anti-corruption obligations; or (iii) any
										breach of its confidentiality obligations; or (iv)
										infringement of intellectual property rights.
									</li>
									<li>
										25. Either Party may terminate this T&amp;C immediately on
										formal written notice and without liability, if the other
										Party: (i) becomes subject to an Insolvency Event; or (ii)
										becomes subject to a Force Majeure Event which continues for
										forty-five (45) consecutive days; or (iii) commits a
										material breach, and, in the case of a breach capable of
										remedy, fails to remedy such breach within thirty (30)
										calendar days after receiving formal written notice to do
										so; or (ii) commits a series of breaches which when taken
										together amount to a material breach.
									</li>
									<li>
										26. VITSL may terminate this T&amp;C immediately on formal
										notice to the Customer and without liability, if there is a
										change in control of the Customer which results in the
										Customer being controlled by: (i) a competitor; or (ii) a
										person in relation to which VITSL has a documented internal
										policy of not trading; or (iii) Customer has violated the
										Applicable Law.
									</li>
									<li>
										27. VITSL may suspend or terminate Services, take other
										action and/or collect any applicable financial penalty as
										levied by any governmental authority for any unauthorized or
										unlawful use of the Services. In the event Customer fails to
										pay any Charges due within thirty (30) calendar days of the
										payment due date, VITSL shall have the right to terminate
										the T&amp;C as applicable by serving Customer with seven (7)
										calendar days written notice of such termination. If such
										termination is within the Lock in Period, VITSL shall be
										entitled to recover not only the Charges but also Exit
										Penalty.
									</li>
									<li>
										28. Damages alone may not be an adequate remedy for breach
										by a Party and accordingly a Party shall be entitled to seek
										remedies of injunction, specific performance or other
										equitable relief for any threatened or actual.
									</li>
									<li>
										29. This T&amp;C and related documents shall be subject to
										laws of India, with jurisdiction of Courts in Mumbai, over
										any dispute, controversy or claim (including any dispute
										regarding its existence, validity or termination) herein.
									</li>
									<li>
										30. Neither Party without prior written consent of other,
										not assign, novate, subcontract or otherwise transfer any of
										its rights or obligations, save that VITSL may assign,
										novate, subcontract or otherwise transfer any of its rights
										or obligations herein and also subcontract after due
										intimation to us/Customer.
									</li>
									<li>
										31. This arrangement between Parties herein are on principal
										to basis.
									</li>
									<li>
										32. Neither Party shall be responsible for failure due to a
										‘Force Majeure Event’, provided that the subjected Party:
										(i) has promptly notified of the circumstances which may
										result in failure to perform its obligations; (ii) could not
										have avoided the effect of the Force Majeure Event by taking
										precautions.
									</li>
									<li>
										33. VITSL shall not be liable to the Customer or End User
										(i) for damages to or loss of data, information or other
										content in the course of implementation or usage of the
										Services; (ii) for any malfunctions or interruptions in the
										Services caused as a result of the use of the Services by
										third parties or any Force Majeure Event; (iii) for any
										message content, or regarding any interactive actions or
										omissions by or on behalf of the Customer or its End User;
										and (iv) for use of the Services and the transacted
										business/transmitted communication. VITSL does not guarantee
										of complete security of Customer’s communications.
									</li>
								</ul>
							</div>
							<div className='fright width49'>
								<ul className='list'>
									<li>
										34. Customer agrees that Services cannot be downgraded
										during the Lock in Period unless Parties agree in writing.
										In case the downgrade is done prior to the expiry of the
										applicable Lock in Period, the same can be done by providing
										prior written notice of not less than thirty (30) days and
										the Customer paying the Early Downgrade Charge related to
										the applicable Services. ‘Early Downgrade Charge’ means an
										amount equivalent to the difference between agreed monthly
										charges for existing and downgrades services, multiplied by
										the number of months remaining in the original Lock in
										Period (measured from the date of downgrade to the expiry of
										the Lock in Period). Early Downgrade Charges needs to be
										paid upfront by Customer before implementation of the
										downgrade. A reduction in core Service offering leads to a
										reduced commercial implication is considered as a downgrade.
									</li>
									<li>
										35. The date on which We/Customer has certified in writing
										that the acceptance criteria has been met shall be deemed
										the Service Commencement Date.  Any acceptance test that has
										not been rejected in accordance with the foregoing clauses
										within five (5) calendar days in case of Private Cloud
										Services and within two (2) days for C-location Services,
										following receipt of an Acceptance Test Results Report shall
										be deemed to be accepted, and the following Business Day
										shall be considered as the Service Commencement
									</li>
									<li>
										36. All prices for Services are exclusive of applicable
										Taxes, fees, levy’s, duties which will be payable extra at
										applicable prevalent rates, as agreed by Parties. Any change
										in Taxes, government levies, license fees and duties to be
										borne by Customer.
									</li>
									<li>
										37. Any installation, commissioning or maintenance of any
										equipment or hardware used for the Service provisioned shall
										be our responsibility unless the same is supplied by VITSL
										or mutually agreed. We shall be solely be responsible /
										liable for any damages/mishandling/theft of such equipment
										or hardware deployed by VITSL towards provisioning of
										Services. Additional cost would be charged for replacement
										of equipment or hardware.
									</li>
									<li>
										38. Service payment terms shall be quarterly in advance
										unless otherwise agreed by Parties for recurring charges /
										for any other charges thereto. One time charges shall be
										paid within fifteen (15) days from the date of invoice and
										may be applied on the first bill generated.
									</li>
									<li>
										39. I/We as Customer agree that We must raise dispute on the
										received invoices from VITSL within thirty (30) days,
										otherwise We waive our right of dispute the invoice. Payment
										of invoices to be done as per due date mentioned on the
										respective invoice and for delay&#39;s in payments of
										Service charges invoices VITSL shall have a right to charge
										us interest @2% per month.
									</li>
									<li>
										40. I/We hereby acknowledge that in case you have not been
										provided regular GST Registration number from mentioned
										states/UT to VITSL, I/We will become disqualified for
										claiming input Tax Credit of GST (CGST, SGST/UTGST, IGST)
										charged by VITSL on provision of services being made in the
										mentioned States/UT to I/We. Further I/We also hereby
										declare that while making invoice payments you will neither
										hold nor make any deductions of any kind on account of
										non-availment of Input Tax Credit by us in the said
										States/UT.
									</li>
									<li>
										41. Service level agreement: VITSL provides Service Levels
										as per the terms agreed between Parties. Customers shall
										allow VITSL or its representatives to carryout maintenance
										activities as and when required by VITSL.
									</li>
									<li>
										42. Also the pricing of the Services provided under the
										agreed Commercial Terms has an approximate increase of 10%
										years on year basis.
									</li>
									<li>
										43. The products purchased for the Services would have
										Transit insurance @1% of the total declared value of the
										asset and the billing for the Service will start once the
										rack is powered ON.
									</li>
									<li>
										44. We understand that in the event the term or usage of a
										Service extends beyond the agreed expiry date or termination
										date of this T&amp;C, then the T&amp;C shall be extended to
										the expiration/termination of the applicable Service. We
										shall be bound to make all payments of Service during the
										extended period as per last agreed pricing terms including
										but not limited to a charge
									</li>
								</ul>
							</div>
						</div>
					</div>
				</page>
				<page size='A4'>
					<div className='section2 font8'>
						<div className='over_hid'>
							<div className='fleft width49'>
								<ul className='list'>
									<li>
										of 300% of the monthly recurring charges for the Co-location
										Services provided our equipment’s installed wasn’t removed
										from the location within fifteen (15) days from the date of
										expiry or termination till upto the date the said Co-
										location Service(s) are not returned by the Customer to
										VITSL.
									</li>
									<li>
										45. It is further agreed that the T&amp;C binds us, their
										executors, administrators, successors and permitted
										assignees to the T&amp;C along with the terms of MSA &amp;
										Schedule (if any).
									</li>
									<li>
										46. If any term or provision of this T&amp;C is held to be
										invalid, illegal or unenforceable in any respect, such
										provision will be ineffective only to that extent without
										invalidating the remainder of such provision or this
										T&amp;C.
									</li>
									<li>
										47. Except as required by Applicable Law, the Customer shall
										not issue any announcement or other communication to any
										third party concerning its dealings with VITSL in relation
										to this T&amp;C in any promotional, advertising or other
										materials, without VITSL’s prior express written consent.
										Customer shall not issue any announcement or other
										communication to any third party concerning its dealings
										with VITSL in relation to this T&amp;C.
									</li>
									<li>
										48. VITSL may change, amend or revised the above T&amp;C at
										any time as and when necessary in order to comply with any
										statutory, legal or regulatory requirements and the Customer
										agrees to abide by such modified terms and conditions. Such
										changes, amendments or revisions shall be deemed effective
										upon posting an update and duly dated regulatory compliance
										to the Customer via email, post or through any other medium
										opted by VITSL.
									</li>
									<li>
										<p>
											49. <strong>DEFINITIONS</strong> <br />“
											<strong>Applicable Law</strong>” means all laws,
											legislation, regulations, directions, binding codes of
											practice, or rules or requirements of any relevant
											government or governmental agency as may be applicable to
											the Services;
											<br /> “<strong>Charge</strong>” shall include all
											payments under the T&amp;C which are due and payable by
											the Customer to VITSL, whether as Annual Recurring Charges
											or Non-Recurring Charges and includes one-time
											registration, testing and installation, Service Equipment
											and Service rental, billing, network service/feature
											change, transfer /shifting, reconnection, usage based
											charges and Security and all other fees in respect of
											provision of the Service and chargeable to the Customer;
											<br /> “<strong>Confidential Information</strong>” means
											all financial, business and technical or other data and
											all other confidential information (whether written, oral
											or in electronic form or on magnetic or other media)
											concerning the business of a disclosing Party that a
											receiving Party receives or accesses as a result of any
											discussions or dealings under this T&amp;C or learns
											during visits to a disclosing Party’s premises.
											<br /> “<strong>Force Majeure Event</strong>” means an
											event which is beyond a Party&#39;s control, including:
											(i) abnormal weather conditions; (ii) nuclear, chemical or
											biological contamination; (iii) war, civil commotion or
											terrorist attack; (iv) interruption or failure of a
											utility service including electric power, gas or water;
											(v) strikes or other forms of industrial action by the
											employees, agents or subcontractors of that Party; (vi)
											acts of God, floods or earthquakes; (vii) the imposition
											of a sanction, embargo or breaking off of diplomatic
											relations; or (viii) expiry, suspension or termination of
											the telecom license of VITSL.
											<br />“<strong>Exit Charge</strong>” means an amount
											equivalent to the agreed monthly charges multiplied by the
											number of months remaining in the Lock in Period (measured
											from the date of termination to the expiry of the Lock in
											Period);
											<br /> “<strong>Lock in Period</strong>” means the period
											commencing from the Service
										</p>
									</li>
								</ul>
							</div>
							<div className='fright width49'>
								<ul className='list'>
									<li>
										<p>
											Commencement Date, during which a Customer cannot
											terminate the T&amp;C / Services, whether in whole or
											part, without the payment of Exit Charges as specified in
											the relevant Schedules;
											<br />“<strong>Services</strong>” as specified in the
											respective COF and/or Service Schedule;
											<br /> “<strong>Service Commencement Date</strong>” means
											the date when the Services are certified by VITSL as being
											active or the date when the Services are delivered to the
											Customer as confirmed by VITSL in writing.
											<br /> “<strong>Tax</strong>” or “<strong>Taxes</strong>”
											means all Goods and Services Tax (“GST”), CGST, SGST,
											UTGST, IGST, GST compensation cess or all indirect taxes,
											including VAT, sales tax, service tax, excise duty,
											privilege tax, duties, fees, levies or surcharges imposed
											by, or pursuant to the laws, statutes or regulations of
											any governmental agency or authority;
										</p>
									</li>
									<div className='terms-sign' style={{ marginBottom: '30px' }}>
										{firstCaps !== '' ? (
											<div style={{ padding: '0px' }} className={Signcss}>
												{firstCaps + '  .  '}
												<span className='under_line'>{secondBold}</span>
											</div>
										) : (
											''
										)}
										{imageData !== null && imageData !== '' ? (
											<div style={{ padding: '0px' }} className='signate_img'>
												<img
													src={
														imageData !== null
															? 'data:' +
															  imageType +
															  ';base64,' +
															  this.encode(imageData)
															: ''
													}
													alt={'Company Seal'}
												/>
											</div>
										) : (
											''
										)}
									</div>
								</ul>
							</div>
						</div>
					</div>
				</page>
			</Fragment>
		)
	}
}
